import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import CarouselHeader from './CarouselHeader';

const images = [
  {
    cityName: 'Dubai',
    capRate: 4.8,
    rentalYield: 7.1,
    properties: 35354,
    src: '/images/hero/dubai.png',
  },
  {
    cityName: 'San Francisco',
    capRate: 2.1,
    rentalYield: 5.7,
    properties: 7657,
    src: '/images/hero/san_francisco.png',
  },
];

const ImageCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Handle previous and next buttons
  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 9000); // Adjust timing to match the total animation duration (e.g., image scale + header slide)

    return () => clearTimeout(timer); // Clear the timer on cleanup
  }, [activeIndex]); // Run this effect every time the activeIndex changes


  return (
    <div className="relative h-[500px] md:h-[720px] w-full md:w-[665px]">
      {/* Carousel Image */}
      <div className="relative w-full h-full">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 w-full h-full ${
              index === activeIndex ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-700 ease-in-out`}
          >
            {/* Animate Image Scale */}
            <motion.img
              key={activeIndex} // Key ensures re-mounting on each slide change
              src={image.src}
              alt={image.cityName}
              initial={{ scale: 1 }} // Start zoomed in
              animate={{ scale: 1.4 }} // Zoom out to normal
              transition={{ duration: 1, ease: 'easeIn',delay:2.2 ,stiffness: 70, damping: 30,}}
              className="w-full h-full object-cover"
            />

            {/* Only show header after the image has zoomed out */}
            {index === activeIndex && (
              <motion.div
                key={activeIndex + '-header'} // Key ensures re-mounting for the header
                initial={{ opacity: 0, y: 50 }} // Start off-screen
                animate={{ opacity: 1, y: 0 }} // Slide up into view
                transition={{ delay: 2.6, duration: 0.8, ease: 'easeInOut' }} // Delay to ensure image scale finishes
              >
                <CarouselHeader
                  cityName={image.cityName}
                  capRate={image.capRate}
                  rentalYield={image.rentalYield}
                  properties={image.properties}
                />
              </motion.div>
            )}

            <div className="w-[694px] h-[292px] mix-blend-multiply bg-gradient-to-b from-[#1C5BD8] to-[rgba(28,91,216,0)]"></div>
          </div>
        ))}
      </div>

      {/* Controls */}
      <button
        onClick={handlePrev}
        className="absolute left-4 bottom-4 h-[40px] sm:h-[50px] p-3 sm:p-4 bg-white/20 rounded-full border border-white/30 backdrop-blur-lg text-white"
      >
        <FaArrowLeft />
      </button>
      <button
        onClick={handleNext}
        className="absolute right-4 bottom-4 h-[40px] sm:h-[50px] p-3 sm:p-4 bg-white/20 rounded-full border border-white/30 backdrop-blur-lg text-white"
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default ImageCarousel;
