import { motion } from 'framer-motion';
import React from 'react';

const CarouselHeader = ({ cityName, properties, rentalYield, capRate }) => {
  // Variants for the animation
  const springVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 1.3, // Stagger the animations by 0.2s for each item
        duration:0.4,
        type:'spring',
        stiffness: 80,
        damping: 20,
      },
    }),
  };

  return (
    <div className="absolute top-0 left-0 w-full h-min p-4 sm:p-10 z-10 flex flex-col justify-start items-start gap-[15px]">
      {/* City Name */}
      <motion.div
        custom={1} // Custom value for the delay
        variants={springVariants}
        initial="hidden"
        animate="visible"
        className="w-full flex justify-start items-center gap-[10px]"
      >
        <div className="text-white text-[20px] sm:text-[24px] font-semibold leading-[24px] sm:leading-[28.80px]">
          {cityName}
        </div>
      </motion.div>

      {/* Property Stats */}
      <div className="w-full flex flex-wrap sm:flex-nowrap justify-start items-start gap-4 sm:gap-6">
        {/* First Row (Properties and Rental Yield) */}
        <motion.div
          custom={2} // Custom value for delay
          variants={springVariants}
          initial="hidden"
          animate="visible"
          className="w-1/2 sm:w-auto flex justify-start items-start gap-1 sm:gap-2"
        >
          <div className="text-white text-[12px] sm:text-[14px] font-semibold leading-[18px] sm:leading-[21px]">
            {properties.toLocaleString()}
          </div>
          <div className="text-[#FCFCFD] text-[12px] sm:text-[14px] font-normal capitalize leading-[18px] sm:leading-[21px]">
            Properties
          </div>
        </motion.div>

        {/* Animated Divider Line */}
        <motion.div
          custom={3} // Custom delay for the divider line
          variants={springVariants}
          initial="hidden"
          animate="visible"
          className="hidden sm:block w-[18px] h-0 text-white"
        >|</motion.div>

        <motion.div
          custom={3}
          variants={springVariants}
          initial="hidden"
          animate="visible"
          className="w-1/2 sm:w-auto flex justify-start items-start gap-1 sm:gap-2"
        >
          <div className="text-white text-[12px] sm:text-[14px] font-semibold leading-[18px] sm:leading-[21px]">
            {rentalYield} %
          </div>
          <div className="text-[#FCFCFD] text-[12px] sm:text-[14px] font-normal capitalize leading-[18px] sm:leading-[21px]">
            Gross Rental Yield
          </div>
        </motion.div>

        {/* Animated Divider Line */}
        <motion.div
          custom={4} // Custom delay for the divider line
          variants={springVariants}
          initial="hidden"
          animate="visible"
          className="hidden sm:block w-[18px] h-0  text-white"
        >|</motion.div>

        {/* Second Row (Capitalization Rate) */}
        <motion.div
          custom={4}
          variants={springVariants}
          initial="hidden"
          animate="visible"
          className="w-full sm:w-auto flex justify-start sm:justify-start items-start gap-1 sm:gap-2"
        >
          <div className="text-white text-[12px] sm:text-[14px] font-semibold leading-[18px] sm:leading-[21px]">
            {capRate}%
          </div>
          <div className="text-[#FCFCFD] text-[12px] sm:text-[14px] font-normal capitalize leading-[18px] sm:leading-[21px]">
            Capitalization Rate
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CarouselHeader;
