import { Modal, Spinner } from "flowbite-react";
import React, { useState } from 'react';
import CitySelector from "./CitySelector";
import PhoneNumberInput from "./PhoneNumberInput";

const customTheme = {
  root: {
    base: "fixed inset-x-0 top-0 z-50 h-full overflow-hidden md:inset-0",
    show: {
      on: "flex bg-[#FCFCFD] bg-opacity-100 dark:bg-opacity-100",
      off: "hidden",
    },
  },
  content: {
    base: "relative h-full w-full p-0 md:h-full",
    inner: "relative flex h-full flex-col bg-white shadow dark:bg-gray-700",
  },
  body: {
    base: "flex-1 overflow-hidden p-4 sm:p-6 h-full",
    popup: "pt-0",
  },
  header: {
    base: "w-full flex justify-between items-center p-6 sm:p-8 rounded-t border-b mb-8 sm:mb-10 dark:border-gray-600",
    popup: "border-b-0 p-2",
    title: "text-[#212633] text-lg sm:text-2xl font-semibold leading-[24px] sm:leading-[30px]",
    close: {
      base: "p-2 sm:p-4 rounded-full border border-[#212633] flex items-center justify-center",
      icon: "w-4 h-4 text-[#212633]",
    },
  },
  footer: {
    base: "flex items-center space-x-1 rounded-b border-gray-200 p-4 sm:p-6 dark:border-gray-600 h-max",
    popup: "h-max",
  },
};

const cities = [
  "Dubai",
  "Riyadh",
  "New York",
  "London",
  "Los Angeles",
  "San Francisco",
  "Singapore",
  "Madrid",
];

function CustomModal({ isOpen, onClose }) {
  const [selectedCities, setSelectedCities] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCountryCode, setphoneCountryCode] = useState(null);
  const [phoneCountry, setphoneCountry] = useState('US');

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(firstName,lastName,phoneNumber,phoneCountry,phoneCountryCode,email);
    

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      selectedCities.length > 0 &&
      phoneCountryCode &&
      phoneNumber !== ''
    ) {
      setLoading(true)

      try {
        const response = await fetch('http://localhost:5000/save-data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            phoneCountryCode,
            phoneNumber,
            selectedCities,
            phoneCountry,
          }),
        });

        const result = await response.json();
        console.log(result);

        if (response.ok) {
          setLoading(false)
        }
      } catch (error) {
        console.error('Error saving data:', error);
      }finally{
        setShowSuccessMessage(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setSelectedCities([]);
        setPhoneNumber('')
        setphoneCountryCode(null)
        setphoneCountry('US')
        setLoading(false)
        setTimeout(() => {
          setFadeOut(true);

          setTimeout(() => {
            setShowSuccessMessage(false);
            setFadeOut(false);
            onClose(false);
          }, 500);
        }, 3000);
      }
    } else {
      console.error("Please fill in all fields");
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className={`fixed w-full h-full inset-0 z-50 flex items-end justify-center ${
        fadeOut ? "opacity-0 transition-opacity duration-1000" : "opacity-100"
      } bg-[#646871] bg-opacity-90`}
    >
      <div className="w-full sm:w-3/4 md:w-2/3 lg:w-[40%] h-full bg-[#E9EAEB] flex flex-col items-center justify-center px-4 sm:px-[383px]">
        <Modal
          theme={customTheme}
          size={"2xl"}
          dismissible={false}
          show={isOpen}
          onClose={() => onClose(false)}
        >
          {showSuccessMessage ? (
            <div className="w-full h-full flex flex-col justify-center items-center transition-opacity duration-900">
              <div className="p-8 border-[3px] border-[#27BB36] rounded-full flex justify-center items-center">
                <svg
                  width="48"
                  height="49"
                  viewBox="0 0 48 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Interface essential/Check">
                    <path
                      id="Icon"
                      d="M17.8926 36.9692C17.1998 36.9687 16.5284 36.7285 15.9926 36.2892L7.03261 28.9492C5.80745 27.8849 5.65081 26.0392 6.67908 24.7836C7.70736 23.528 9.54769 23.3178 10.8326 24.3092L17.7726 29.9892L37.7726 11.5692C38.5244 10.6753 39.7265 10.299 40.8538 10.6047C41.981 10.9104 42.8284 11.8425 43.0255 12.9936C43.2227 14.1448 42.7339 15.3058 41.7726 15.9692L19.9326 36.1692C19.38 36.687 18.6499 36.9733 17.8926 36.9692Z"
                      fill="#27BB36"
                    />
                  </g>
                </svg>
              </div>
              <div
                className="mt-8 text-center text-[#27BB36] text-lg sm:text-xl
font-semibold
font-['Inter']
leading-[24px] sm:leading-[30px]"
              >
                Submitted Successfully
              </div>
              <div
                className="mt-4 text-center text-[#646871] text-sm sm:text-base
font-normal
font-['Inter']
leading-normal"
              >
                Thank you for submitting for early access. <br />
                We're excited to have you on board!
              </div>
            </div>
          ) : (
            <>
              <Modal.Header>Get Early Access</Modal.Header>
              <Modal.Body>
                <form
                  className="flex flex-col gap-4 sm:gap-6 pt-[10px] h-max"
                  onSubmit={handleSubmit}
                >
                  <div className="relative z-0">
                    <input
                      type="text"
                      id="first_name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className="block py-2.5 px-0 w-full text-sm sm:text-base text-[#212633] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#646871] dark:focus:border-[#646871] focus:outline-none focus:ring-0 focus:border-[#212633] peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="first_name"
                      className="text-base font-normal absolute text-sm text-[#646871] dark:text-[#646871] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-[#646871] peer-focus:dark:text-[#212633] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First Name
                    </label>
                  </div>
                  <div className="relative z-0">
                    <input
                      type="text"
                      id="last_name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className="block py-2.5 px-0 w-full text-sm sm:text-base text-[#212633] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#646871] dark:focus:border-[#646871] focus:outline-none focus:ring-0 focus:border-[#212633] peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="last_name"
                      className="text-base font-normal absolute text-sm text-[#646871] dark:text-[#646871] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-[#646871] peer-focus:dark:text-[#212633] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="relative z-0">
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block py-2.5 px-0 w-full text-sm sm:text-base text-[#212633] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#646871] dark:focus:border-[#646871] focus:outline-none focus:ring-0 focus:border-[#212633] peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="email"
                      className="text-base font-normal absolute text-sm text-[#646871] dark:text-[#646871] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:text-[#646871] peer-focus:dark:text-[#212633] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email
                    </label>
                  </div>
                  <div>
                    <PhoneNumberInput value={phoneNumber} setValue={setPhoneNumber} code={phoneCountryCode} setCode={setphoneCountryCode} phoneCountry={phoneCountry} setphoneCountry={setphoneCountry}/>
                  </div>
                  <CitySelector
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                    cities={cities}
                  />
                  <div className="w-full h-full py-6 sm:py-9 flex flex-col justify-center items-end gap-2.5">
                    <button
                      type="submit"
                      className="h-10 w-[100px] px-4 py-2 bg-[#1D5BD8] rounded-lg flex justify-center items-center gap-4"
                    >
                      <div className="flex-1 flex flex-row gap-3 justify-center items-center text-center text-white text-sm font-medium font-['Inter'] leading-[19.6px]">
                      {loading&&<Spinner  className="text-white"  aria-label="Spinner button example" size="sm" />}
                        Submit
                      </div>
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default CustomModal;
