import { motion } from "framer-motion";
import React, { useState } from "react";
import CustomModal from "../components/CustomModal";
import ImageCarousel from "../components/ImageCarousel";

  // Variants for the letter-by-letter fade-in
  const headingVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8, // Delay to make it appear after the heading
        duration: 0.6,
        ease: 'easeInOut',
        stiffness: 30,          // Control how stiff the spring is
        damping: 10, 
      },
    },
  };

  // Variants for the paragraph fade-in
  const paragraphVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1.1, // Delay to make it appear after the heading
        duration: 0.7,
        ease: 'easeInOut',
        stiffness: 30,          // Control how stiff the spring is
        damping: 10, 
      },
    },
  };

  // Variants for the button slide-up animation
  const buttonVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1.6, // Delay to make it appear after the paragraph
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 30,          // Control how stiff the spring is
        damping: 10, 
      },
    },
  };

  const letterVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1, // Delay between each letter
        duration: 0.8,
      },
    }),
  };

  // Variants for each statistic block
  const blockVariant = {
    hidden: { opacity: 0 },
    visible: (i) => ({
      opacity: 1,
      transition: {
        delay: i * 0.9, // Delay between each block
        duration: 0.8,
      },
    }),
  };
  
function HeroBanner() {

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <section
      id="Hero"
      className="bg-white bg-inherit min-h-screen flex flex-col md:flex-row p-3 grow shrink basis-0 self-stretch overflow-hidden"
    >
      <div className="relative w-full md:w-2/3 flex flex-col justify-center px-4 py-1 lg:px-4">
      <div className="flex flex-col sm:flex-row justify-between p-4 lg:px-2 sm:p-2 inset-y-0 left-0 w-full h-max z-50">
          {/* Logo sliding up */}
          <motion.div
            className="flex justify-center sm:justify-start items-center mb-4 sm:mb-0"
            initial={{ y: 100, opacity: 0 }} // Start off-screen below
            animate={{ y: 0, opacity: 1 }} // Move to the original position
            transition={{ type: "spring", stiffness: 70, damping: 20, delay: 0.2 }} // Control spring effect
          >
            <a href="/" className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto h-[50px] sm:h-10 sm:w-[130px] object-contain"
                src="/images/logo.png"
                alt="Smart Bricks"
              />
            </a>
          </motion.div>

          <div className="flex-grow"></div>

          <div className="flex flex-col sm:flex-row gap-4 items-center">
            {/* How It Works button sliding up */}
            <motion.a
              href="#How_it_works"
              className="w-full sm:w-auto px-4 py-2 bg-white flex justify-center items-center font-['Inter'] text-sm sm:text-base font-semibold leading-6 text-[#212633] font-['Inter'] leading-tight"
              initial={{ y: 100, opacity: 0 }} // Start off-screen below
              animate={{ y: 0, opacity: 1 }} // Move to the original position
              transition={{ type: "spring", stiffness: 70, damping: 20, delay: 0.2 }} // Slight delay after logo
            >
              How It Works
            </motion.a>

            {/* Chat with Us button sliding from the right */}
            <motion.a
              href="https://wa.me/971566789831?text=Hello%20Smart%20Bricks,%20I'm%20interested%20in%20exploring%20real%20estate%20investment%20opportunities"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto px-4 py-2 bg-white border font-['Inter'] border-[#212633] rounded-md flex justify-center items-center font-semibold leading-6 text-[#212633] text-sm sm:text-base font-['Inter'] leading-tight"
              initial={{ x: 100, opacity: 0 }} // Start off-screen to the right
              animate={{ x: 0, opacity: 1 }} // Move to the original position
              transition={{ type: "spring", stiffness: 70, damping: 20, delay: 0.4 }} // Slight delay after How It Works button
            >
              Chat with Us
            </motion.a>
          </div>
</div>

<div className="flex flex-col justify-center items-center gap-4 sm:gap-6 w-full h-full pt-16 sm:pt-24 pb-6 sm:pb-9 px-2">
  <div className="flex flex-col justify-center items-center gap-6 sm:gap-8 h-auto sm:h-64">
    <motion.h1  initial="hidden"
          animate="visible"
          variants={headingVariants} className="text-center text-[#212633] text-3xl sm:text-4xl md:text-6xl font-bold leading-[2.8rem] sm:leading-[3.5rem] md:leading-[4.2rem] break-words font-['Inter']">
      Simplify Your Real <br /> Estate Investing <br /> Journey
    </motion.h1>
    <motion.p  initial="hidden"
          animate="visible"
          variants={paragraphVariants} className="w-full sm:w-4/5 md:w-[500px] text-center text-[#646871] font-['Inter'] text-sm sm:text-base md:text-lg font-normal leading-6 sm:leading-7 break-words">
      Unlock top-tier opportunities and maximize your ROI <br className="hidden md:block" />
      with Smart Bricks' AI-driven insights.
    </motion.p>
  </div>
  <motion.div
        className="flex justify-center items-center gap-4 pt-6 sm:pt-10 md:pt-6 md:pt-7"
        initial="hidden"
        animate="visible"
        variants={buttonVariants}
      >
        <button
          onClick={() => setModalOpen(true)}
          className="w-full sm:w-auto px-6 py-2 bg-[#1D5BD8] font-['Inter'] rounded-lg text-center text-white text-sm sm:text-base font-medium leading-5 break-words"
        >
          Get Early Access
        </button>
      </motion.div>
</div>


<div className="w-full h-max flex flex-col md:flex-row justify-start items-center gap-6 mt-4 mb-4 px-4 md:px-10 pb-5">
      {/* First Statistic */}
      <motion.div
        className="flex-1 flex flex-col justify-start items-start gap-[12px] md:gap-[18px] self-stretch"
        custom={0} // first block
        initial="hidden"
        animate="visible"
        variants={blockVariant}
      >
        <motion.div
          className="text-[#212633] text-xl md:text-2xl font-['Inter'] font-semibold leading-7 md:leading-9"
          initial="hidden"
          animate="visible"
        >
          {Array.from('5M+').map((letter, index) => (
            <motion.span key={index} custom={index} variants={letterVariant}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
        <motion.div
          className="w-full text-[#646871] font-['Inter'] text-sm font-normal capitalize leading-[18px] md:leading-[21px]"
          initial="hidden"
          animate="visible"
        >
          {Array.from('unique properties analyzed').map((letter, index) => (
            <motion.span key={index} custom={index} variants={letterVariant}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
      </motion.div>

      {/* Divider (Hidden on small devices, visible on medium and larger screens) */}
      <motion.div custom={1} // first block
        initial="hidden"
        animate="visible"
        variants={blockVariant} className="hidden md:block w-[42px] h-0 rotate-90 origin-top border border-[#DADEE5]"></motion.div>

      {/* Second Statistic */}
      <motion.div
        className="flex-1 flex flex-col justify-start items-start gap-[12px] md:gap-[18px] self-stretch"
        custom={1} // second block
        initial="hidden"
        animate="visible"
        variants={blockVariant}
      >
        <motion.div
          className="text-[#212633] text-xl md:text-2xl font-['Inter'] font-semibold leading-7 md:leading-9"
          initial="hidden"
          animate="visible"
        >
          {Array.from('10k+').map((letter, index) => (
            <motion.span key={index} custom={index} variants={letterVariant}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
        <motion.div
          className="w-full text-[#646871] font-['Inter'] text-sm font-normal capitalize leading-[18px] md:leading-[21px]"
          initial="hidden"
          animate="visible"
        >
          {Array.from('data points').map((letter, index) => (
            <motion.span key={index} custom={index} variants={letterVariant}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
      </motion.div>

      {/* Divider (Hidden on small devices, visible on medium and larger screens) */}
      <motion.div custom={2} // first block
        initial="hidden"
        animate="visible"
        variants={blockVariant} className="hidden md:block w-[42px] h-0 rotate-90 origin-top border border-[#DADEE5]"></motion.div>

      {/* Third Statistic */}
      <motion.div
        className="flex-1 flex flex-col justify-start items-start gap-[12px] md:gap-[18px] self-stretch"
        custom={2} // third block
        initial="hidden"
        animate="visible"
        variants={blockVariant}
      >
        <motion.div
          className="text-[#212633] text-xl md:text-2xl font-['Inter'] font-semibold leading-7 md:leading-9"
          initial="hidden"
          animate="visible"
        >
          {Array.from('20+').map((letter, index) => (
            <motion.span key={index} custom={index} variants={letterVariant}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
        <motion.div
          className="w-full text-[#646871] font-['Inter'] text-sm font-normal capitalize leading-[18px] md:leading-[21px]"
          initial="hidden"
          animate="visible"
        >
          {Array.from('years data history').map((letter, index) => (
            <motion.span key={index} custom={index} variants={letterVariant}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
      </motion.div>
    </div>
      </div>

      <motion.div
        className="relative w-max-full md:w-max-full flex items-center justify-center rounded-3xl md:h-min"
        initial={{ opacity: 0, x: 400 }} // Start off-screen to the right
        animate={{ opacity: 1, x: 0 }} // Animate to the final position
        transition={{ type: "spring", stiffness: 70, damping: 30, delay: 1.8 }} // Adding a spring effect
      >
        <div className="h-full w-full rounded-3xl overflow-hidden md:h-max">
          <ImageCarousel />
        </div>
      </motion.div>

      <CustomModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </section>
  );
}

export default HeroBanner;