import { Spinner } from 'flowbite-react';
import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import Footer from './sections/Footer';
import Header from './sections/Header';
import HeroBanner from './sections/HeroBanner';

// Lazy load components
const BackedBy = React.lazy(() => import('./sections/BackedBy'));
const ExploreCity = React.lazy(() => import('./sections/ExploreCity'));
const Faq = React.lazy(() => import('./sections/Faq'));
const HowItWorks = React.lazy(() => import('./sections/HowItWorks'));
const HowItWorksAnimation = React.lazy(() => import('./sections/HowItWorksAnimation'));
const Testimonial = React.lazy(() => import('./sections/Testimonial'));

function App() {
  // Intersection Observer to check if section is in view
  const [sectionsInView, setSectionsInView] = useState({
    exploreCity: false,
    howItWorks: false,
    backedBy: false,
    testimonial: false,
    HowItWorksAnimation:false,
    faq: false,
  });

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const section = entry.target.getAttribute('data-section');
          setSectionsInView((prev) => ({
            ...prev,
            [section]: true,
          }));
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const targets = document.querySelectorAll('[data-section]');
    targets.forEach((target) => observer.observe(target));

    return () => {
      targets.forEach((target) => observer.unobserve(target));
    };
  }, []);

  return (
    <>
      <HeroBanner />
      <Header />

      {/* ExploreCity Section */}
      <div data-section="exploreCity">
        {sectionsInView.exploreCity && (
          <Suspense fallback={<Spinner  className="text-white flex justify-center items-center"  aria-label="Spinner button example" size="lg" />}>
            <ExploreCity />
          </Suspense>
        )}
      </div>

      {/* HowItWorksAnimation Section */}
      <div data-section="HowItWorksAnimation">
        {sectionsInView.HowItWorksAnimation && (
          <Suspense fallback={<Spinner  className="text-white flex justify-center items-center"  aria-label="Spinner button example" size="lg" />}>
            <HowItWorksAnimation />
          </Suspense>
        )}
      </div>

      {/* BackedBy Section */}
      <div data-section="backedBy">
        {sectionsInView.backedBy && (
          <Suspense fallback={<Spinner  className="text-white flex justify-center items-center"  aria-label="Spinner button example" size="lg" />}>
            <BackedBy />
          </Suspense>
        )}
      </div>

      {/* Testimonial Section */}
      <div data-section="testimonial">
        {sectionsInView.testimonial && (
          <Suspense fallback={<Spinner  className="text-white flex justify-center items-center"  aria-label="Spinner button example" size="lg" />}>
            <Testimonial />
          </Suspense>
        )}
      </div>

      {/* FAQ Section */}
      <div data-section="faq">
        {sectionsInView.faq && (
          <Suspense fallback={<Spinner  className="text-white flex justify-center items-center"  aria-label="Spinner button example" size="lg" />}>
            <Faq />
          </Suspense>
        )}
      </div>

      <Footer />
    </>
  );
}

export default App;
