import { Navbar } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import CustomModal from '../components/CustomModal';

const navBarTheme={
  "root": {
    "base": "w-full h-max transition-all sticky bg-white/70 border-b border-[#DADEE5] backdrop-blur-xl z-50 sm:px-4 ",
    "rounded": {
      "on": "",
      "off": ""
    },},
  "toggle": {
    "base": "inline-flex items-center rounded-lg p-2 text-sm text-[#212633] bg-transparent border-none focus:outline-none  md:hidden",
    "icon": "h-6 w-6 shrink-0"
  },
  "link": {
    "base": "w-full block py-2 pl-3 pr-4 md:p-0",
    "active": {
      "on": "bg-cyan-700 text-[#212633] dark:text-[#212633] md:bg-transparent md:text-[#212633]",
      "off": "w-full border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white"
    },},
    "collapse": {
      "base": "w-full md:block md:w-auto ",
      "list": " w-full  mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
      "hidden": {
        "on": "hidden",
        "off": ""
      }
    },
}
const Header = () => {

  
  const [isModalOpen, setModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const exploreSection = document.getElementById('Explore_city');
      const exploreTop = exploreSection?.getBoundingClientRect().top;

      if (exploreTop && exploreTop <= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`w-full h-max transition-all sticky top-0 z-30 
      ${isVisible ? '' : 'hidden'}`}>
      
      {/* Wrap the entire nav in Navbar component */}
      <Navbar theme={navBarTheme} fluid={true} rounded={true}>
        <div className="flex items-center justify-between px-4 md:px-7 py-4 w-full">
          <div className="flex items-center">
            <a href="#Hero" className="-m-1.5 p-1.5">
              <img className="h-8 w-[100px] md:h-10 md:w-[130px] object-contain" src="/images/logo.png" alt="Smart Bricks" />
            </a>
          </div>
          <div className="flex-grow"></div>
          <div className="hidden md:flex justify-end gap-10 items-center">
            <div className="flex gap-6 px-2">
              <a href="#Explore_city" className="text-center text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
                Explore Cities
              </a>
              <a href="#How_it_works" className="text-center text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
                How It Works
              </a>
              <a href="#Testimonials" className="text-center text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
                Testimonials
              </a>
              <a href="#FAQ" className="text-center text-[#212633] text-sm font-medium font-semibold font-['Inter'] leading-tight">
                FAQ’s
              </a>
            </div>
            <div className="flex gap-6">
              <a
                href="https://wa.me/971566789831?text=Hello%20Smart%20Bricks,%20I'm%20interested%20in%20exploring%20real%20estate%20investment%20opportunities"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 border border-[#212633] rounded-md text-center text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
                Chat with Us
              </a>
              <button onClick={() => setModalOpen(true)} className="px-4 py-2 bg-[#1D5BD8] rounded-md text-center text-white text-sm font-medium font-['Inter'] leading-tight">
                Get Early Access
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <div className="md:hidden flex justify-end items-center">
            <button onClick={() => setModalOpen(true)} className="px-4 py-2 bg-[#1D5BD8] rounded-md text-center text-white text-sm font-medium font-['Inter'] leading-tight">
              Get Early Access
            </button>
            <Navbar.Toggle  />
          </div>
        </div>

        {/* Mobile Navigation Collapse */}
        <Navbar.Collapse open={isNavOpen} className='md:hidden '>
        <div className='w-full flex flex-col justify-center items-center gap-4  px-2 bg-white/70 backdrop-blur-lg'>
          <Navbar.Link href="#Explore_city" className="w-full text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
            Explore Cities
          </Navbar.Link>
          <Navbar.Link href="#How_it_works" className="w-full text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
            How It Works
          </Navbar.Link>
          <Navbar.Link href="#Testimonials" className="w-full text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
            Testimonials
          </Navbar.Link>
          <Navbar.Link href="#FAQ" className="w-full text-[#212633] text-sm font-medium font-['Inter'] leading-tight">
            FAQ’s
          </Navbar.Link>
          <a
            href="https://wa.me/94775338556?text=Hello%20Smart%20Bricks,%20I'm%20interested%20in%20exploring%20real%20estate%20investment%20opportunities"
            target="_blank"
            rel="noopener noreferrer"
            className="w-full px-4 py-2 border border-[#212633] rounded-md text-center text-[#212633] text-sm font-semibold font-medium font-['Inter'] leading-tight">
            Chat with Us
          </a>
          </div>
        </Navbar.Collapse>
        
      </Navbar>

      <CustomModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </header>
  );
};

export default Header;
