import React from 'react';

const CitySelector = ({selectedCities,setSelectedCities,cities}) => {

  const handleCityClick = (city) => {
    if (selectedCities.includes(city)) {
      setSelectedCities(selectedCities.filter((selected) => selected !== city));
    } else {
      setSelectedCities([...selectedCities, city]);
    }
  };

 

  return (
    <div className="flex flex-col gap-4">
      <label
        className="text-[#646871] text-sm text-base font-normal font-['Inter'] leading-normal"
      >
        I’m interested in
      </label>
      <div className="flex flex-wrap gap-4 justify-center">
        {cities.map((city) => {
          const isSelected = selectedCities.includes(city);
          return (
            <div
              key={city}
              className={`flex items-center gap-2 px-4 py-2 border ${
                isSelected
                  ? 'border-none bg-[#E9EFFC]'
                  : 'border-[#F4F6F8]'
              } rounded-lg cursor-pointer`}
              onClick={() => handleCityClick(city)}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Interface essential/Check">
                  <path
                    id="Icon"
                    d="M6.70973 13.6759C6.44991 13.6758 6.19816 13.5857 5.99723 13.4209L2.63723 10.6684C2.17779 10.2693 2.11905 9.5772 2.50466 9.10636C2.89026 8.63552 3.58038 8.55669 4.06223 8.92844L6.66473 11.0584L14.1647 4.15094C14.4466 3.81575 14.8974 3.67463 15.3202 3.78926C15.7429 3.90389 16.0606 4.25342 16.1346 4.68511C16.2085 5.1168 16.0252 5.55217 15.6647 5.80094L7.47473 13.3759C7.2675 13.5701 6.99372 13.6775 6.70973 13.6759Z"
                    fill={isSelected ? '#1D5BD8' : '#E9EAEB'}
                  />
                </g>
              </svg>
              <span
                className={`text-sm font-medium font-['Inter'] leading-tight ${
                  isSelected ? 'text-[#1D5BD8]' : 'text-[#212633]'
                }`}
              >
                {city}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CitySelector;
