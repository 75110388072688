import React, { useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const PhoneNumberInput = ({value,setValue,code,setCode,setphoneCountry,phoneCountry}) => {

  const [isFocused, setIsFocused] = useState(false);
  const phoneInputRef = useRef(null);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (value === '' && !code) {
      setIsFocused(false);
    }
  };

  const handleCountrySelectClick = () => {
    const selectElement = phoneInputRef.current.querySelector('#select-c');
    console.log(selectElement);
    if (selectElement) {
      
    }    };


    const handleInputChange = (e) => {
      const formattedValue = formatPhoneNumber(e.target.value);
      setValue(formattedValue);
    };
  
    const formatPhoneNumber = (input) => {
      const cleaned = input.replace(/\D+/g, ""); // Remove non-digit characters
      const match = cleaned.match(/^(\d{0,3})(\d{0,4})(\d{0,3})$/);
      if (match) {
        return `${match[1]}${match[2] ? `-${match[2]}` : ""}${match[3] ? `-${match[3]}` : ""}`;
      }
      return input;
    };
    const handleCountryChange = (countryCode) => {
      if(countryCode){
        const countryName = Object.values(countryCode).join('');
        setphoneCountry(countryName);
      }
     
    };
  
    const handlePhoneInputChange = (newCode) => {
      if(newCode){
        const phoneCode = Object.values(newCode).join('');
        setCode(phoneCode);
      }
      
    };
  return (
    <div className="relative z-0">
      <div
        className={`flex items-center relative  pt-3 ${
          isFocused ? "border-b-2 border-[#212633]" : ""
        }`}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {isFocused || value !==''? (
          <>
            <div ref={phoneInputRef}>
              <PhoneInput
                international
                defaultCountry="US"
                value={code}
                
                onCountryChange={handleCountryChange}
                onChange={handlePhoneInputChange}
                className="border-none outline-none w-[110px] text-[#212633] bg-transparent"
                onFocus={handleFocus}
                onBlur={handleBlur}
                focusInputOnCountrySelection={false}
                inputComponent={({...props})=>{
                 if(props?.value){
                  setCode(props?.value)
                 }
                  return( <>

                  <p {...props} className='pl-1 flex justify-center border-none flex gap-1 '><span>{phoneCountry}</span>({props?.value})</p>
                  </>)
                }}
                
                
              />
            </div>

            <FaChevronDown
              className="ml-3 px-[1px] text-[#212633] dark:text-[#646871] cursor-pointer"
              onClick={handleCountrySelectClick}
            />
            <div className="text-gray-500 dark:text-[#646871] px-2">|</div>
            <input
              type="text"
              id="phone_number"
              className="block py-2.5 px-0 w-full text-sm text-[#212633] bg-transparent border-none appearance-none dark:text-white dark:border-[#646871] dark:focus:border-[#646871] focus:outline-none focus:ring-0 focus:border-[#212633] peer"
              placeholder=" "
              value={value}
              onChange={handleInputChange}
              inputMode="numeric"
              pattern="\d{3}-\d{4}-\d{2,3}"
              maxLength={12}
              onFocus={handleFocus}
              onBlur={handleBlur}
              
            />
          </>
        ) : (
          <>
            <input
              type="text"
              id="phone_number"
              className="block py-2.5 px-0 w-full text-sm text-[#212633] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#646871] dark:focus:border-[#646871] focus:outline-none focus:ring-0 focus:border-[#212633] peer"
              placeholder=" "
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoFocus={code || value !==''}
              onChange={handleFocus}
            />
          </>
        )}
      </div>
      <label
        htmlFor="phone_number"
        className={`absolute text-sm text-[#646871] dark:text-[#646871] duration-300 transform ${
          isFocused || value
            ? "-translate-y-6 scale-75"
            : "translate-y-3 scale-100"
        } top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-[#646871] peer-focus:dark:text-[#212633] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto`}
      >
        Phone Number
      </label>
    </div>
  );
};

export default PhoneNumberInput;



