import React from 'react';

const Footer = () => {
  return (
    <div className="w-full px-4 sm:px-6 lg:px-8 pt-16 bg-[#20283b] flex flex-col justify-start items-center gap-10">
      <div className="w-full flex flex-col lg:flex-row justify-start items-start gap-6 lg:gap-12">
        <div className="flex-1 flex flex-col justify-start items-start gap-6">
          <div className="pt-1 flex justify-start items-start">
            <div className="flex justify-start items-start gap-2  pr-[1px] ">
              <img
                src="/images/footer/smart_brick_logo.png"
                alt="Smart Brick Logo"
                className="w-[39px] h-[33px] object-contain"
              />
              <img
                src="/images/footer/smart_bricks.png"
                alt="Smart Bricks"
                className="w-[75px] h-[33px] object-contain"
              />
            </div>
          </div>
          <div className="text-white text-sm sm:text-base font-normal font-['Inter'] leading-[21px]">
            Empower Your Real Estate Investments with AI
            <br />
            Discover, Analyze, and Invest in Prime Properties with Smart Bricks
          </div>
        </div>
        <div className="flex-1 flex flex-col lg:flex-row justify-start items-start gap-6 lg:gap-8">
          <div className="flex-1 flex flex-col justify-start items-start gap-6">
            <div className="flex flex-col justify-center items-start gap-4">
              <div className="text-white text-sm font-bold font-['Inter'] leading-[21px]">
                COMPANY
              </div>
              <div className="flex flex-col justify-start items-start gap-2">
                <a href='#How_it_works' className="text-white text-sm font-normal font-['Inter'] leading-[21px] ">
                  How It Works
                </a>
                <a href='#FAQ' className="text-white text-sm font-normal font-['Inter'] leading-[21px] ">
                  FAQ’s
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-start items-start gap-6">
            <div className="flex flex-col justify-start items-start gap-4">
              <a href='#HERO' className="opacity-0 text-white text-sm font-bold font-['Inter'] leading-[21px]">
                ABOUT
              </a>
              <div className="flex flex-col justify-start items-start gap-2">
                <a href='/' className="text-white text-sm font-normal font-['Inter'] leading-[21px]">
                  Terms & Conditions
                </a>
                <a href='/' className="text-white text-sm font-normal font-['Inter'] leading-[21px]">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-4 border-t border-[#646871] flex justify-center items-center">
        <div className="text-center text-[#fcfcfd] text-sm sm:text-base font-normal font-['Inter'] leading-[21px]">
          Copyright © 2024 Smart Bricks
        </div>
      </div>
    </div>
  );
};

export default Footer;
